<template>
  <div class="pageContainer app-container studentContainer eventBox" style="position: relative">
    <el-tabs v-model="activeName" type="card" style="" @tab-click="changeTab">
      <el-tab-pane label="" name="td">
        <span slot="label">
          <i>优秀毕业生</i>
        </span>
      </el-tab-pane>
    </el-tabs>
    <el-row>
      <el-input v-model="formData.stu_name" clearable size="small" placeholder="请输入学生姓名"
        style="max-width: 200px; margin-right: 10px" />
      <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
      <el-button size="small" icon="el-icon-refresh" @click="onReset" type="warning">重置</el-button>
      <el-button type="primary" size="small" @click="saveInfo()">添加</el-button>
    </el-row>
    <el-table stripe :data="dataList" style="width: 100%; margin: 20px 0; color: #000" border size="mini">
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="stu_name" label="姓名" width="80"></el-table-column>
      <el-table-column prop="phone" label="联系电话" width="100"></el-table-column>
      <el-table-column prop="sex" label="性别" width="60">
        <template slot-scope="scope">
          <span v-if="scope.row.sex == '1'">男</span>
          <span v-if="scope.row.sex == '2'">女</span>
          <span v-if="scope.row.sex == '0'">未知</span>
        </template>
      </el-table-column>
      <el-table-column prop="departments_name" label="系部" width="120"></el-table-column>
      <el-table-column prop="grade_name" label="年级" width="100"></el-table-column>
      <el-table-column prop="class_name" label="班级" width="80"></el-table-column>
      <el-table-column prop="major_name" label="专业" width="120"></el-table-column>
      <el-table-column prop="start_year" label="入学年份" width="80"></el-table-column>
      <el-table-column prop="enter" label="就业单位" min-width="120"></el-table-column>
      <!--      <el-table-column-->
      <!--        prop="event_desc"-->
      <!--        label="优秀事迹"-->
      <!--        min-width="120"-->
      <!--      ></el-table-column>-->
      <el-table-column prop="create_date" label="创建时间" min-width="150"></el-table-column>
      <el-table-column fixed="right" label="操作" width="150">
        <template slot-scope="scope">
          <div class="flexStart">
            <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info" iconColor="red"
              @confirm="delectMessage(scope.row)" placement="left" title="是否确定删除？">
              <a slot="reference" size="small" style="
                  color: red;
                  font-size: 12px;
                  margin-right: 10px;
                  cursor: pointer;
                ">删除</a>
            </el-popconfirm>
            <el-button type="text" size="small" @click="findOne(scope.row)" style="margin: 0 !important">编辑</el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="current_page" :page-size="per_page" layout="total, prev, pager, next" :total="count">
    </el-pagination>

    <el-dialog title="编辑优秀实习生1212" :visible.sync="dialogVisible" width="800px" :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true"
      :destroy-on-close="true">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="学生姓名" prop="stu_name">
          <el-input v-model="ruleForm.stu_name" size="small"></el-input>
        </el-form-item>

        <el-form-item label="照片" prop="avatar">
          <el-upload action="" :http-request="uploadOss" :on-success="uploadFiles" :show-file-list="false" name="image">
            <div class="tzsbox">
              <img v-if="ruleForm.avatar" :src="ruleForm.avatar" class="avatar"
                style="max-width: 100%;max-height: 100%;">
              <div v-else style="width: 100px; height: 100px; font-size: 100px;  color: #ccc; ">
                <i class="el-icon-plus"></i>
              </div>
            </div>
          </el-upload>

          <!--          <div v-if="ruleForm.avatar" :style="{backgroundImage:'url('+ruleForm.avatar+')'}" class="tzsbox">-->
          <!--            <img :src="ruleForm.avatar" v-if="ruleForm.avatar" style="width: 100%; height: 100%; opacity: 0;"-->
          <!--                 v-viewer />-->
          <!--          </div>-->
        </el-form-item>



        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="ruleForm.phone" size="small"></el-input>
        </el-form-item>

        <el-form-item label="性别" prop="sex">
          <!-- <el-input v-model="" size="small"></el-input> -->
          <el-radio-group v-model="ruleForm.sex" size="small">
            <el-radio :label="2">女</el-radio>
            <el-radio :label="1">男</el-radio>
            <el-radio :label="0">未知</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="年级名称" prop="grade_name">
          <el-input v-model="ruleForm.grade_name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="系部名称" prop="departments_name">
          <el-input v-model="ruleForm.departments_name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="专业名称" prop="major_name">
          <el-input v-model="ruleForm.major_name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="入学年份" prop="start_year">
          <el-input v-model="ruleForm.start_year" size="small"></el-input>
        </el-form-item>
        <el-form-item label="就业单位" prop="enter">
          <el-input v-model="ruleForm.enter" size="small"></el-input>
        </el-form-item>
        <el-form-item label="优秀事迹">
          <div style="line-height: initial">
            <UEditor ref="ue" @ready="editorReady" style="width: 100%"></UEditor>
          </div>
        </el-form-item>
        <el-form-item label="备注" prop="remarks">
          <el-input v-model="ruleForm.remarks" size="small"></el-input>
        </el-form-item>
        <el-form-item label="排序号" prop="sort">
          <el-input-number size="small" v-model="ruleForm.sort" :min="1" :max="100"></el-input-number>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">保存
          </el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import UEditor from "../../notify/ueditor.vue";

export default {
  components: {
    UEditor,
  },
  data() {
    return {
      editor: null,
      dialogVisible: false,
      count: 0,
      current_page: 1,
      per_page: 13,
      activeName: "td",
      dataList: [],
      showSP: false,
      formData: {},
      spword: "",
      showPrint: false,
      ruleForm: {
        id: "",
        create_by: "",
        enter: "",
        event_desc: "",
        remarks: "",
        stu_name: "",
        sort: 1,
        phone: "",
        sex: 0,
        grade_name: "",
        class_name: "",
        departments_name: "",
        major_name: "",
        start_year: "",
        avatar: '',
      },
      rules: {
        stu_name: [
          { required: true, message: "请输入学生姓名", trigger: "change" },
        ],
        event_desc: [
          { required: true, message: "请选择输入优秀事迹", trigger: "change" },
        ],
      },

      props: { emitPath: false, value: "id", label: "stu_name" },
      studentsTree: [],
    };
  },
  mounted() {
    console.log(sessionStorage.getItem("auth"));
    this.getlist();
    this.getStudentsTree();
  },
  methods: {
    uploadFiles(e) {
      let _this = this
      this.ruleForm.avatar = e.src
      this.ruleForm = { ..._this.ruleForm }
      console.log(301, this.ruleForm.avatar)

    },
    getlist() {
      this.$http.post("/api/by_stu_event_list", {
        page: this.current_page,
        pagesize: this.per_page,
        stu_name: this.formData.stu_name,
      })
        .then((res) => {
          console.log(res);
          this.dataList = res.data.data;
          this.count = res.data.page.count;
          this.current_page = res.data.page.current_page;
          this.per_page = res.data.page.per_page;
        });
    },
    editorReady(editorInstance) {
      editorInstance.setContent(this.ruleForm.event_desc);
      editorInstance.addListener("contentChange", () => {
        this.ruleForm.event_desc = editorInstance.getContent();
      });
      this.editor = editorInstance;
    },
    getStudentsTree() {
      this.$http.post("/api/by_students_tree").then((res) => {
        if (res.data && res.data.length > 0) {
          this.studentsTree = res.data;
        }
      });
    },

    changeTab() {
      this.getlist();
    },
    onSearch() {
      this.getlist();
    },
    onReset() {
      this.formData = {};
      this.getlist();
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      this.current_page = val;
      this.getlist();
    },
    submitForm(formName) {
      console.log(246)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http.post("/api/by_stu_event_edit", this.ruleForm)
            .then((res) => {
              this.dialogVisible = false;
              this.getlist();
            });
        } else {
          console.log("保存失败!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      let _this = this;
      setTimeout(() => {
        _this.editor.setContent("");
      }, 300);
    },
    findOne(e) {
      // this.ruleForm.id = e.id;
      // this.ruleForm.create_by = e.create_by;
      // this.ruleForm.enter = e.enter;
      // this.ruleForm.event_desc = e.event_desc;
      // this.ruleForm.remarks = e.remarks;
      // this.ruleForm.sort = e.sort;
      // this.ruleForm.stu_name = e.stu_name;
      // this.ruleForm.phone = e.phone;
      // this.ruleForm.sex = e.sex;
      // this.ruleForm.grade_name = e.grade_name;
      // this.ruleForm.class_name = e.class_name;
      // this.ruleForm.departments_name = e.departments_name;
      // this.ruleForm.major_name = e.major_name;
      // this.ruleForm.start_year = e.start_year;
      // this.ruleForm.avatar = e.avatar;
      // this.dialogVisible = true;
      // let _this = this;
      // setTimeout(() => {
      //   _this.editor.setContent(e.event_desc);
      // }, 300);
      const longParam = encodeURIComponent(JSON.stringify(e));
      this.$router.push(
        { path: '/bygz_edit_event', query: { param: longParam } }
      )

    },
    saveInfo(e) {
      // this.ruleForm = {
      //   id: "",
      //   create_by: "",
      //   enter: "",
      //   event_desc: "",
      //   remarks: "",
      //   stu_name: "",
      //   sort: 1,
      //   phone: "",
      //   sex: 0,
      //   grade_name: "",
      //   class_name: "",
      //   departments_name: "",
      //   major_name: "",
      //   start_year: "",
      //   avatar: ''
      // };
      // this.dialogVisible = true;
      // this.$router.push("/h5result/" + this.$route.params.id)
      this.$router.push("/bygz_edit_event")
    },
    delectMessage(e) {
      this.$http.post("/api/by_stu_event_delete", {
        id: e.id,
      })
        .then((res) => {
          this.getlist();
        });
    },
    handleCancel() { },
    printIframe() {
      var iframe = document.getElementById("printPage");
      iframe.contentWindow.focus();
      iframe.contentWindow.print();
    },
  },
};
</script>
<style lang="less" type="text/less">
.tzsbox {
  width: 300px;
  border: 1px dotted #ccc;
  height: 300px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
}
</style>
